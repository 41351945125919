define("discourse/plugins/docker_manager/discourse/templates/connectors/admin-upgrade-header/upgrade-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DockerManager::UpgradeNotice @versionCheck={{this.versionCheck}} />
  */
  {
    "id": "8s3istjp",
    "block": "[[[8,[39,0],null,[[\"@versionCheck\"],[[30,0,[\"versionCheck\"]]]],null]],[],false,[\"docker-manager/upgrade-notice\"]]",
    "moduleName": "discourse/plugins/docker_manager/discourse/templates/connectors/admin-upgrade-header/upgrade-header.hbs",
    "isStrictMode": false
  });
});